import React, { useContext } from 'react';
import './processSteps.css';
import translations from './translation.json'; // Import translations
import { LanguageContext } from '../languageContext'; // Import LanguageContext

// Define your steps with image paths
const steps = [
  { title: 'Extraction', img: '/1Extracting.png' },
  { title: 'Milling / Mixing', img: '/2Mixing.png' },
  { title: 'Testing', img: '/3Testing.png' },
  { title: 'Export', img: '/4Export.png' },
  { title: 'Refining', img: '/5Refining.png' },
];

function Process() {
  const { selectedLanguage } = useContext(LanguageContext);

  return (
    <div className="timeline">
      {steps.map((step, index) => (
        <div key={index} className="timeline-step">
          <div className="timeline-content">
            <img src={step.img} alt={translations[selectedLanguage][step.title]} className="timeline-image" />
            <h3>{translations[selectedLanguage][step.title]}</h3>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Process;
