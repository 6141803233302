import React, { useContext } from 'react';
import { LanguageContext } from '../languageContext'; // Import LanguageContext

const Modal = ({ toggleModal }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const translations = {
        en: {
            modalHeader: "Catalog",
            modalText: "CRG Online Catalog is free to use with over 5 features. Prices are adjusted to protect the buyer against suffering substantial losses. At the end, it is the buyer's responsibility to check the material being purchased, to assure it has not been tampered with and is not damaged.",
            goToCatalog: "Go to Catalog",
            closeButton: "Close"
        },
        ar: {
            modalHeader: "الفهرس",
            modalText: "كتالوج CRG على الإنترنت مجاني الاستخدام مع أكثر من 5 ميزات. تم ضبط الأسعار لحماية المشتري من تكبد خسائر كبيرة. في النهاية، يتحمل المشتري مسؤولية التحقق من المواد المشتراة، للتأكد من أنها لم تتم تلاعب بها ولا تالفة.",
            goToCatalog: "الانتقال إلى الفهرس",
            closeButton: "إغلاق"
        }
    };

    return (
        <div className="modal-overlay" onClick={toggleModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className='modal-header'>{translations[selectedLanguage].modalHeader}</div>
                <div className='modal-text'>{translations[selectedLanguage].modalText}</div>
                <div className='modal-button-container'>
                    <a href="https://crgtrading-8253692c6089.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                        <button>{translations[selectedLanguage].goToCatalog}</button>
                    </a>
                    <button onClick={toggleModal}>{translations[selectedLanguage].closeButton}</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
