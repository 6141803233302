import React from 'react';
import './App.css';
import Header from './home/header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './home/homemain';
import { LanguageProvider } from './languageContext'; // Import LanguageProvider

function App() {
  return (
    <HelmetProvider>
      <LanguageProvider> {/* Wrap your entire app with LanguageProvider */}
        <Router>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
        </Router>
      </LanguageProvider>
    </HelmetProvider>
  );
}

export default App;
