import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './home.css'

function ApiData() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countKilogramCrg, setCountKilogramCrg] = useState(0);
  const [countPurchasesCrg, setCountPurchasesCrg] = useState(0);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://www.crgegypt.com';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make GET request to fetch data from the backend endpoint
        const response = await axios.get(`${baseUrl}/months`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!data) return;

    const intervalId = setInterval(() => {
      const totalKilogramCrg = data.reduce((total, item) => total + item.kilogramCrg, 0);
      const totalPurchasesCrg = data.reduce((total, item) => total + item.purchasesCrg, 0);

      if (countKilogramCrg < totalKilogramCrg) {
        setCountKilogramCrg(prevCount => Math.min(prevCount + 50, totalKilogramCrg));
      }

      if (countPurchasesCrg < totalPurchasesCrg) {
        setCountPurchasesCrg(prevCount => Math.min(prevCount + 10, totalPurchasesCrg));
      }

      if (countKilogramCrg >= totalKilogramCrg && countPurchasesCrg >= totalPurchasesCrg) {
        clearInterval(intervalId);
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [data, countKilogramCrg, countPurchasesCrg]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data) return null;

  return (
    <div className='countermain'>
      <div className='counterbox'>Processed {countKilogramCrg + 11214} Kg</div>
      <div className='counterbox'>Customers {(countPurchasesCrg / 4).toFixed(0)}</div>
    </div>
  );
}

export default ApiData;
