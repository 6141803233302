import React, { createContext, useState } from 'react';

// Create a new context for managing the language state
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('selectedLanguage') || 'en' // Default language is English
    );

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', language); // Save selected language to local storage
    };

    return (
        <LanguageContext.Provider value={{ selectedLanguage, handleLanguageChange }}>
            {children}
        </LanguageContext.Provider>
    );
};
