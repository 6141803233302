import React, { useContext } from 'react';
import './home.css';
import HomeOne from './homeone';
import ApiData from './apidata';
import Services from './services';
import Contact from './contact';
import Footer from './footer';
import FloatingIcon from './floatingIcon';
import Process from './processSteps';
import { LanguageContext } from '../languageContext'; // Import LanguageContext

function Home() {
  const { selectedLanguage } = useContext(LanguageContext); // Access selectedLanguage from context

  // Define translations for each text
  const translations = {
    en: {
      crgEgypt: "CRG EGYPT",
      mission: "Our mission is to deliver exceptional value and exceed customer expectations.",
      strive: "We strive to innovate continuously, cultivate strong relationships, and set the benchmark for excellence in customer satisfaction."
    },
    ar: {
      crgEgypt: "مصر CRG",
      mission: "مهمتنا هي تقديم قيمة استثنائية وتجاوز توقعات العملاء.",
      strive: "نحن نسعى للابتكار باستمرار وزراعة علاقات قوية، ووضع المعيار الذي يتجاوز التميز في رضا العملاء."
    }
  };

  return (
    <div className="home">
      <header className="home-header">
        {translations[selectedLanguage].crgEgypt}
      </header>
      <header className="home-header-rotate">
        {/* Add direction styling to handle Arabic text */}
        <div className={selectedLanguage === 'ar' ? 'rtl-text' : ''}>
          {translations[selectedLanguage].mission.split(" ").map((word, index) => (
            <span key={index}>{word}</span>
          ))}
        </div>
      </header>
      <header className="home-header-rotate">
        {/* Add direction styling to handle Arabic text */}
        <div className={selectedLanguage === 'ar' ? 'rtl-text' : ''}>
          {translations[selectedLanguage].strive.split(" ").map((word, index) => (
            <span key={index}>{word}</span>
          ))}
        </div>
      </header>

      <ApiData />
      <HomeOne />
      <Process />
      <Services />
      <Contact />
      <Footer />
      <FloatingIcon /> {/* Add the FloatingIcon component */}
    </div>
  );
}

export default Home;
