import React, { useContext } from 'react';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LanguageContext } from '../languageContext'; // Import LanguageContext

const Footer = () => {
    const { selectedLanguage } = useContext(LanguageContext);
    const translations = {
        en: {
            getInTouch: "Get in Touch",
            facebookLink: "https://www.facebook.com/profile.php?id=61556246223438",
            twitterLink: "https://twitter.com",
            linkedinLink: "https://linkedin.com",
            whatsappLink: "https://wa.me/201093604630",
            copyRight: `${new Date().getFullYear()} CRG EGYPT | All Rights Reserved`,
            representedBy: "Represented By",
            legalAdvisors: "Legal Advisors",
            lawFirm: "Sadany & Khalifa Law Firm",
            companyDocuments: "Company Documents",
            commercialRegistration: "Commercial Registration",
            taxCard: "Tax Card",
            taxCertificate: "Tax Certificate",
            licenses: "Licenses",
            exportLicense: "Export License"
        },
        ar: {
            getInTouch: "تواصل معنا",
            facebookLink: "https://www.facebook.com/profile.php?id=61556246223438",
            twitterLink: "https://twitter.com",
            linkedinLink: "https://linkedin.com",
            whatsappLink: "https://wa.me/201093604630",
            copyRight: `${new Date().getFullYear()} CRG EGYPT | جميع الحقوق محفوظة`,
            representedBy: "ممثلة من قبل",
            legalAdvisors: "المستشارين القانونيين",
            lawFirm: "مكتب السعدني وخليفة للمحاماة",
            companyDocuments: "وثائق الشركة",
            commercialRegistration: "التسجيل التجاري",
            taxCard: "بطاقة ضريبية",
            taxCertificate: "شهادة ضريبية",
            licenses: "التراخيص",
            exportLicense: "رخصة التصدير"
        }
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section social">
                    <h3>{translations[selectedLanguage].getInTouch}</h3>
                    <div className="social-icons">
                        <a href={translations[selectedLanguage].facebookLink} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a href={translations[selectedLanguage].whatsappLink} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                    </div>
                </div>
                <div className="footer-section social">
                    <h3>{translations[selectedLanguage].representedBy}</h3>
                    <a href="https://sadanykhalifa.com/en" target="_blank" rel="noopener noreferrer">
                        {translations[selectedLanguage].lawFirm}
                    </a>
                </div>
                <div className="footer-section documents">
                    <h3>{translations[selectedLanguage].companyDocuments}</h3>
                    <ul>
                        <li><a href={`${process.env.PUBLIC_URL}/commercialRegistration.pdf`} target="_blank" rel="noopener noreferrer">{translations[selectedLanguage].commercialRegistration}</a></li>
                     {/*    <li><a href={`${process.env.PUBLIC_URL}/taxCard.pdf`} target="_blank" rel="noopener noreferrer">{translations[selectedLanguage].taxCard}</a></li> */}
                     {/*    <li><a href={`${process.env.PUBLIC_URL}/taxcertificate.pdf`} target="_blank" rel="noopener noreferrer">{translations[selectedLanguage].taxCertificate}</a></li> */}
                    </ul>
                </div>
               {/*  <div className="footer-section licenses">
                    <h3>{translations[selectedLanguage].licenses}</h3>
                    <ul>
                        <li><a href={`${process.env.PUBLIC_URL}/exportLicense.pdf`} target="_blank" rel="noopener noreferrer">{translations[selectedLanguage].exportLicense}</a></li>
                    </ul>
                </div>*/}
            </div>
            <div className="footer-bottom">
                {translations[selectedLanguage].copyRight}
            </div>
        </footer>
    );
};

export default Footer;
