import React, { useState, useContext } from 'react';
import './contact.css';
import axios from 'axios';
import MapWidget from './googlemaps';
import { LanguageContext } from '../languageContext'; // Import LanguageContext

const Contact = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const { selectedLanguage } = useContext(LanguageContext);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crgegypt-12187cff21d1.herokuapp.com';
    const translations = {
        en: {
            contact: "Contact Us",
            nameLabel: "Name:",
            phoneLabel: "Phone:",
            messageLabel: "Message:",
            sendButton: "Send",
            successMessage: "Message sent successfully!",
            errorMessage: "Failed to send message. Please try again later."
        },
        ar: {
            contact: "اتصل بنا",
            nameLabel: "الاسم:",
            phoneLabel: "رقم الهاتف:",
            messageLabel: "الرسالة:",
            sendButton: "إرسال",
            successMessage: "تم إرسال الرسالة بنجاح!",
            errorMessage: "فشل في إرسال الرسالة. يرجى المحاولة مرة أخرى لاحقًا."
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(false);
        setError('');

        try {
            await axios.post(`${baseUrl}/send-message`, { name, phone, message });
            setSuccess(true);
            setName('');
            setPhone('');
            setMessage('');
        } catch (err) {
            setError(translations[selectedLanguage].errorMessage);
        }
    };

    return (
        <div className="contact" id="contact">
            <div className='contact-container-main'>
                <div className='contact-container-map'>
                    <MapWidget />
                </div>
                <div className='contact-container-form'>
                    <div className="services-header">{translations[selectedLanguage].contact}</div>
                    <form onSubmit={handleSubmit}>
                        <label>
                            {translations[selectedLanguage].nameLabel}
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            {translations[selectedLanguage].phoneLabel}
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            {translations[selectedLanguage].messageLabel}
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </label>
                        <button type="submit">{translations[selectedLanguage].sendButton}</button>
                    </form>
                </div>
                {success && <p className="success">{translations[selectedLanguage].successMessage}</p>}
                {error && <p className="error">{error}</p>}
            </div>
        </div>
    );
};

export default Contact;
