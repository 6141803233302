import React from 'react';
import { FaBook } from 'react-icons/fa'; // Import the icon

const FloatingIcon = () => {
  const handleClick = () => {
    window.open('https://crgtrading-8253692c6089.herokuapp.com/', '_blank');
  };

  return (
    <div className="floating-icon" onClick={handleClick}>
      <FaBook size={30} /> {/* Use the icon */}
    </div>
  );
};

export default FloatingIcon;

