import React, { useContext, useEffect, useState } from 'react';
import './home.css'; 
import { LanguageContext } from '../languageContext'; // Import LanguageContext
import translations from './translation.json';

function HomeOne() {
  const { selectedLanguage } = useContext(LanguageContext);
  const [languageTranslations, setLanguageTranslations] = useState({});

  // Retrieve translations for the selected language when it changes
  useEffect(() => {
    setLanguageTranslations(translations[selectedLanguage]);
  }, [selectedLanguage]);

  console.log('Selected language:', selectedLanguage);
  console.log('Language translations:', languageTranslations);

  // Check if languageTranslations is empty
  if (Object.keys(languageTranslations).length === 0) {
    console.log('Language translations are empty!');
  }

  return (
    <div className="main-catalytics-column">
      <div className="paragraph1">
        {languageTranslations.paragraph1}
      </div>
      <div className="main-catalytics-row">
        <img className='responsive-image' src={`${process.env.PUBLIC_URL}/MainCatalytics.jpg`} alt="Example" />
        <div className="paragraph2">
          <ul>
            {Object.keys(languageTranslations).length !== 0 && (
              <>
                <div>{languageTranslations.ourservices}</div>
                <div className='homesmalltext'>{languageTranslations.service1}</div>
                <div className='homesmalltext'>{languageTranslations.service2}</div>
                <div className='homesmalltext'>{languageTranslations.service3}</div>
                <div className='homesmalltext'>{languageTranslations.service4}</div>
                <div className='homesmalltext'>{languageTranslations.service5}</div>
                <div className='homesmalltext'>{languageTranslations.service6}</div>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeOne;
