import React, { useEffect } from 'react';

const MapWidget = () => {
    useEffect(() => {
        // Check if the script is already added to avoid adding it multiple times
        if (!document.querySelector('script[src="https://static.elfsight.com/platform/platform.js"]')) {
            const elfsightScript = document.createElement('script');
            elfsightScript.src = "https://static.elfsight.com/platform/platform.js";
            elfsightScript.setAttribute('data-use-service-core', '');
            elfsightScript.defer = true;
            document.body.appendChild(elfsightScript);
        }


    }, []);

    return (
        <div className="map-widget">
            <div className="elfsight-app-23ac6df7-be27-484e-a10f-7c37afa1d547" data-elfsight-app-lazy></div>
        </div>
    );
};

export default MapWidget;
