import React, { useState, useEffect, useContext } from 'react'; // Import useContext
import logo from './Logo.png';
import './header.css';
import Modal from './modal';
import translations from './translation.json';
import { LanguageContext } from '../languageContext'; // Import LanguageContext

function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
}

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { selectedLanguage, handleLanguageChange } = useContext(LanguageContext); // Get selectedLanguage and handleLanguageChange from LanguageContext
    const scrollPosition = useScrollPosition();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScrollToSection = (e, id) => {
        e.preventDefault();
        scrollToSection(id);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // Renamed the function to avoid conflict
    const handleChangeLanguage = (language) => {
        handleLanguageChange(language);
    };

    return (
        <>
            <header className={`App-header ${scrollPosition > 0 ? 'App-header-black' : ''}`}>
                <img src={logo} className="App-logo" alt="CRG" />
                <div className='headertext'>
                <div className="language-selector">
                        <button className='langselect' onClick={() => handleChangeLanguage('en')}>EN</button>
                        <button className='langselect' onClick={() => handleChangeLanguage('ar')}>AR</button>
                    </div>
                    <nav className={`nav ${isOpen ? 'open' : ''}`}>
                        <ul>
                            <li><a href="#home" onClick={handleScrollToTop}>{translations[selectedLanguage].home}</a></li>
                            <li><a href="#services" onClick={(e) => handleScrollToSection(e, 'services')}>{translations[selectedLanguage].services}</a></li>
                            <li><a href="#contact" onClick={(e) => handleScrollToSection(e, 'contact')}>{translations[selectedLanguage].contact}</a></li>
                            <li><a href="#catalog" onClick={toggleModal}>{translations[selectedLanguage].catalog}</a></li>
                        </ul>
                    </nav>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>

                </div>

            </header>
            {isModalOpen && (
                <Modal toggleModal={toggleModal} />
            )}
        </>
    );
}

export default Header;
