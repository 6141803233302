import React, { useState, useEffect, useContext } from 'react';
import './services.css';
import Modal from './modal';
import { LanguageContext } from '../languageContext'; 
import translations from './translation.json';

function Services() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { selectedLanguage } = useContext(LanguageContext);

    useEffect(() => {
        const serviceItems = document.querySelectorAll('.service-item');
        serviceItems.forEach(item => {
            item.addEventListener('mousemove', (e) => {
                const rect = item.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                const rotateX = (y / rect.height - 0.5) * 20;
                const rotateY = (x / rect.width - 0.5) * -20;

                item.style.transform = `perspective(500px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
            });

            item.addEventListener('mouseleave', () => {
                item.style.transform = 'perspective(500px) rotateX(0) rotateY(0)';
            });
        });
    }, []);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div id="services" className="services">
            <div className="services-header">{translations[selectedLanguage]["Our Services"]}</div>
            <div className="services-list">
                <div className="service-item" onClick={toggleModal}>
                    <h3>{translations[selectedLanguage]["AUTOCATALYST CERAMIC"]}</h3>
                    <img className='responsive-image-services' src={`${process.env.PUBLIC_URL}/CeramicBricks.png`} alt="Example" />
                </div>
                <div className="service-item" onClick={toggleModal}>
                    <h3>{translations[selectedLanguage]["CATALYTIC CONVERTERS"]}</h3>
                    <img className='responsive-image-services' src={`${process.env.PUBLIC_URL}/CatalyticServices.png`} alt="Example" />
                </div>
                <div className="service-item" onClick={toggleModal}>
                    <h3>{translations[selectedLanguage]["ELECTRONIC WASTE PRODUCTS"]}</h3>
                    <img className='responsive-image-services' src={`${process.env.PUBLIC_URL}/WeeeServices.png`} alt="Example" />
                </div>
                <div className="service-item" onClick={toggleModal}>
                    <h3>{translations[selectedLanguage]["OXYGEN SENSORS (Lambda)"]}</h3>
                    <img className='responsive-image-services' src={`${process.env.PUBLIC_URL}/OxygenSensors.png`} alt="Example" />
                </div>
            </div>
            {isModalOpen && <Modal toggleModal={toggleModal} />}
        </div>
    );
}

export default Services;
